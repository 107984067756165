import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

// Define a service using a base URL and expected endpoints
export const patients = createApi({
  reducerPath: "patient",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPatientAIAnalyticDetails: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/schedule/info-data`,
      }),
    }),
  }),
});

export const { useLazyGetPatientAIAnalyticDetailsQuery } = patients;
